<template>
	<footer id="footer">
		<div class="container">
			<h3>Gydo Broos</h3>
			<p>Always looking for the next innovative challenge.</p>
			<div class="social-links">
				<a
					href="https://www.linkedin.com/in/gydo-broos/"
					class="linkedin"
					title="To LinkedIn"
					aria-label="To LinkedIn"
					rel="noopener"
				>
					<i class="bx bxl-linkedin"></i>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'Footer'
}
</script>