<template>
	<span>{{ value }}</span>
</template>

<script>
export default {
	name: "NumberIncrement",

	props: {
		countTo: {
			type: Number,
			required: true
		},
		speed: {
			type: Number,
			required: false,
			default: 100
		}
	},

	data() {
		return {
			inViewport: false,
			value: 0
		}
	},

	created() {
		this.registerEventListener();
	},

	watch: {
		inViewport(isInViewport) {
			if (isInViewport) {
				this.countUp();
			}
		}
	},

	methods: {
		registerEventListener() {
			document.addEventListener('scroll', () => {
				this.isInViewport();
			});
		},

		countUp() {
			setTimeout(() => {
				if (this.value < this.countTo) {
					this.value++;

					this.countUp();
				}
			}, this.speed);
		},

		isInViewport() {
			var rect = this.$el.getBoundingClientRect();
			var html = document.documentElement;

			this.inViewport = (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || html.clientHeight) &&
				rect.right <= (window.innerWidth || html.clientWidth)
			);
		}
	}
}
</script>

<style scoped>

</style>