<template>
    <Header/>

    <PageHome/>

    <Footer/>

    <div v-if="backToTopVisible"
         id="back-to-top"
         aria-label="Back to top"
         @click="backToTop">
        <i class="bx bx-up-arrow-alt"></i>
    </div>
</template>

<script>
import Header from '@/components/Header';
import PageHome from '@/pages/PageHome.vue';
import Footer from "@/components/Footer";

import AOS from 'aos'

export default {
    name: 'App',
    components: {
        PageHome,
        Footer,
        Header
    },

    data() {
        return {
            backToTopVisible: false
        }
    },

    mounted() {
        AOS.init({
            duration: 1000,
            once: true
        });

        document.addEventListener('scroll', this.toggleBackToTopButton);
    },

    unmounted() {
        document.removeEventListener('scroll', this.toggleBackToTopButton);
    },

    methods: {
        toggleBackToTopButton() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            this.backToTopVisible = scrollTop > 100;
        },

        backToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
}
</script>

<style lang="scss">
#back-to-top {
    position: fixed;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    right: 15px;
    bottom: 15px;
    background: #0563bb;
    color: #fff;
    transition: display 0.5s ease-in-out;
    z-index: 99999;
    cursor: pointer;

    i {
        font-size: 24px;
        position: absolute;
        top: 7px;
        left: 8px;
    }

    &:hover {
        color: #fff;
        background: #0678e3;
        transition: background 0.2s ease-in-out;
    }
}
</style>
