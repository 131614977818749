<template>
	<header id="header" class="d-flex flex-column justify-content-center">
		<Menu />
	</header>
</template>

<script>
import Menu from '@/components/Menu'

export default {
	name: 'Header',

	components: {
		Menu
	}
}
</script>
