<template>
	<nav class="nav-menu">
		<ul>
			<li class="active"><a href="#hero"><i class="bx bx-home"></i> <span>Home</span></a></li>
			<li><a href="#about"><i class="bx bx-user"></i> <span>About</span></a></li>
			<li><a href="#resume"><i class="bx bx-file-blank"></i> <span>Resume</span></a></li>
			<li><a href="#strengths"><i class="bx bx-server"></i> <span>Strengths</span></a></li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: "Menu"
}
</script>