<template>
	<div class="content">
		<!-- ======= Hero Section ======= -->
		<section id="hero" class="d-flex flex-column justify-content-center">
			<div class="container" data-aos="zoom-in" data-aos-delay="100">
				<h1>Gydo Broos</h1>
				<p>
					I'm a
					<span id="typed" data-typed-items="Developer, Innovator, Entrepreneur, Team player"></span>
				</p>
				<div class="social-links">
					<a
						href="https://www.linkedin.com/in/gydo-broos/"
						class="linkedin"
						title="To LinkedIn"
						aria-label="To LinkedIn"
						rel="noopener"
					>
						<i class="bx bxl-linkedin"></i>
					</a>
				</div>
			</div>
		</section>
		<!-- End Hero -->

		<main id="main">
			<!-- ======= About Section ======= -->
			<section id="about" class="about">
				<div class="container" data-aos="fade-up">
					<div class="section-title">
						<h2>About</h2>
						<p>
							I’m a jack of all trades. Why? I’m not just a developer, experienced in multiple fields, but also a engineer with a creative mind and eye for detail. I strive to go further than making usable products. They should be engaging. An experience.
							I’m driven, motivated, social and a team player, capable of focusing on the task and taking the lead if necessary.
						</p>
					</div>

					<div class="row">
						<div class="col-lg-4">
							<img src="/assets/img/profile-img.webp" class="profile-img img-fluid" alt="Profile picture" width="281" height="281" loading="lazy" />
						</div>
						<div class="col-lg-8 pt-4 pt-lg-0 content">
							<h3>Full stack developer with an eye for design.</h3>
							<p class="font-italic">
								Writing SOLID code is what I love to do, but I also get great satisfaction from a eye-pleasing design and good user experience.
								<br />This is just part of my story however. My passion goes to finding new areas to innovate with tech and to grow with every project I pick up.
							</p>
							<div class="row">
								<div class="col-lg-6">
									<ul>
										<li>
											<i class="icofont-rounded-right"></i>
											<strong>Age:</strong>
											{{ age }}
										</li>
										<li>
											<i class="icofont-rounded-right"></i>
											<strong>City:</strong> Utrecht area, NL
										</li>
									</ul>
								</div>
								<div class="col-lg-6">
									<ul>
										<li>
											<i class="icofont-rounded-right"></i>
											<strong>Degree:</strong> Computer Science
										</li>
										<li>
											<i class="icofont-rounded-right"></i>
											<strong>E-mail:</strong> gydo@broos.work
										</li>
									</ul>
								</div>
							</div>
							<p>Got an innovative idea an looking for an entrepreneur with a heavy tech background to make it a success? Contact me and let's see what we can do!</p>
						</div>
					</div>
				</div>
			</section>
			<!-- End About Section -->

			<!-- ======= Facts Section ======= -->
			<section id="facts" class="facts">
				<div class="container" data-aos="fade-up">
					<div class="section-title">
						<h2>Facts</h2>
						<p>Lot's of talk, so let's look at the numbers.</p>
					</div>

					<div class="row">
						<div class="col-lg-3 col-md-6">
							<div class="count-box">
								<i class="icofont-simple-smile"></i>
								<NumberIncrement :count-to="years" />
								<p>Years in the field</p>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mt-5 mt-md-0">
							<div class="count-box">
								<i class="icofont-document-folder"></i>
								<NumberIncrement :count-to="years * 6" />
								<p>Projects</p>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
							<div class="count-box">
								<i class="icofont-live-support"></i>
								<NumberIncrement :count-to="8" />
								<p>Frameworks</p>
							</div>
						</div>

						<div class="col-lg-3 col-md-6 mt-5 mt-lg-0">
							<div class="count-box">
								<i class="icofont-users-alt-5"></i>
								<NumberIncrement :count-to="376" :speed="10" />
								<p>Connections</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- End Facts Section -->

			<!-- ======= Skills Section ======= -->
			<section id="skills" class="skills section-bg">
				<div class="container" data-aos="fade-up">
					<div class="section-title">
						<h2>Skills</h2>
						<p>In the last {{ years }} years I've worked on the following skills.</p>
					</div>

					<div class="row skills-content">
						<div class="col-lg-6">
							<div class="progress">
								<span class="skill">
									HTML
									<i class="val">100%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Expert"
										aria-label="Expert"
										aria-valuenow="100"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 100%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									(S)CSS
									<i class="val">85%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
                                        class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="85"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 85%"
                                    ></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									ES6 / VueJS
									<i class="val">85%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
                                        class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="85"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 85%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Python
									<i class="val">25%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
                                        class="progress-bar"
										role="progressbar"
										title="Advanced beginner"
										aria-label="Advanced beginner"
										aria-valuenow="25"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 25%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Communication
									<i class="val">90%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="85"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 90%"
									></div>
								</div>
							</div>
						</div>

						<div class="col-lg-6">
							<div class="progress">
								<span class="skill">
									PHP
									<i class="val">98%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Expert"
										aria-label="Expert"
										aria-valuenow="98"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 98%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Symfony
									<i class="val">90%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
                                        class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="90"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 90%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Laravel
									<i class="val">95%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="95"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 95%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Cloud Hosting
									<i class="val">65%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Competent"
										aria-label="Competent"
										aria-valuenow="65"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 65%"
									></div>
								</div>
							</div>

							<div class="progress">
								<span class="skill">
									Teamwork
									<i class="val">90%</i>
								</span>
								<div class="progress-bar-wrap">
									<div
                                        data-aos="fade-right"
										class="progress-bar"
										role="progressbar"
										title="Proficient"
										aria-label="Proficient"
										aria-valuenow="90"
										aria-valuemin="0"
										aria-valuemax="100"
                                        style="width: 90%"
									></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- End Skills Section -->

			<!-- ======= Resume Section ======= -->
			<section id="resume" class="resume">
				<div class="container" data-aos="fade-up">
					<div class="section-title">
						<h2>Resume</h2>
						<p>Standing still is moving backwards.</p>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<h3 class="resume-title">Summary</h3>
							<div class="resume-item pb-0">
								<h4>Gydo Broos</h4>
								<p>
									<em>Innovative and hardworking Full stack developer/CTO with {{ years }} years of experience developing user-centered digital products from initial concept to final, polished deliverable.</em>
								</p>
								<ul>
									<li>Utrecht area, NL</li>
									<li>gydo@broos.work</li>
								</ul>
							</div>

							<h3 class="resume-title">Education</h3>
							<div class="resume-item">
								<h4>Shopware 6 Certified (advanced) Developer</h4>
								<h5>june 2020, feb 2021</h5>
								<p>
									<em>Shopware Academy</em>
								</p>
								<p>Shopware 6 showed mayor promise in becoming the next big ecommerce platform. In order to ride the wave and to be able to develop high quality plugins on the Symfony framework I acquired the Shopware 6 Certified Developer and Shopware 6 Certified Advanced Developer certificates.</p>
							</div>
                            <div class="resume-item">
                                <h4>Vue School Mastery Course</h4>
                                <h5>june 2020</h5>
                                <p>
                                    <em>Vue School</em>
                                </p>
                                <p>I started working more with Vue and wanted to become more proficient in it, so I followed the Mastery course on Vue school to speed my learning process. Now I work with Vue 3 using a mix of Composition/Options API and Typescript.</p>
                            </div>
							<div class="resume-item">
								<h4>Shopware Certified Developer</h4>
								<h5>may 2019</h5>
								<p>
									<em>Shopware Academy</em>
								</p>
								<p>At ZEO we saw that Shopware had a lot of future potential and focussed more on creating a brand experience than any other ecommerce platform out there. In an effort to become a major player in the market acquired the Certified Developer credential.</p>
							</div>
							<div class="resume-item">
								<h4>React JS Course</h4>
								<h5>may 2018 - jul 2018</h5>
								<p>
									<em>Codecademy Pro Intensive</em>
								</p>
								<p>Javascript Frameworks like React peeked my interest, so in an effort to learn more about them and becoming adequate in using them for my next project or venture, I completed an intensive course at Codecademy building an application connecting to the Spotify API.</p>
							</div>
							<div class="resume-item">
								<h4>UX Design minor</h4>
								<h5>2015 - 2016</h5>
								<p>
									<em>Hogeschool van Amsterdam</em>
								</p>
								<p>During my bachelor I though it would be a valuable addition to dive into user-experience design. This would allow me to develop better applications by thinking in how the user looks and thinks about navigating the application.</p>
							</div>
							<div class="resume-item">
								<h4>Bachelor Computer Science (HBO), 8.2</h4>
								<h5>sept 2012 - jan 2017</h5>
								<p>
									<em>Hogeschool Leiden</em>
								</p>
								<p>I studied for 4 years at the University of applied science Leiden. Here I touched on a variety of subjects related to computer science, from cyber security to coding and business modelling.</p>
							</div>
						</div>

						<div class="col-lg-6">
							<h3 class="resume-title">Professional Experience</h3>
							<div class="resume-item">
								<h4>Chief Executive Officer</h4>
								<h5>Sept 2022 - present</h5>
								<p>
									<em>Inventle BV. Utrecht</em>
								</p>
								<ul>
									<li>Position and building a business in the Web3 space.</li>
								</ul>
							</div>

							<div class="resume-item">
								<h4>Chief Technical Officer</h4>
								<h5>Jun 2021 - present</h5>
								<p>
									<em>DizTech BV. Utrecht</em>
								</p>
								<ul>
									<li>Managing server infrastructure.</li>
									<li>Looking for new technological opportunities.</li>
									<li>Developing and managing new innovative extensions in the e-commerce sector.</li>
								</ul>
							</div>

							<div class="resume-item">
								<h4>Backend developer</h4>
								<h5>Jul 2018 - Jun 2021</h5>
								<p>
									<em>Zeo BV. Utrecht</em>
								</p>
								<ul>
									<li>Creating technical and functional designs.</li>
									<li>Created a middleware application to lower the costs of connecting systems in the ecommerce landscape.</li>
									<li>Lead the transition to Shopware 6 as a new platform to offer to existing and future clients.</li>
									<li>Took the lead in developing plugins for Shopware 6 to offer on the marketplace.</li>
								</ul>
							</div>
							<div class="resume-item">
								<h4>Full stack developer</h4>
								<h5>Feb 2017 - Jul 2018</h5>
								<p>
									<em>Zeo BV. Utrecht</em>
								</p>
								<ul>
									<li>Creating technical and functional designs.</li>
									<li>Developing Magento 1 and Magento 2 webshops for various clients.</li>
									<li>Developing applications in Laravel.</li>
									<li>Executing and developing internal projects.</li>
								</ul>
							</div>
							<div class="resume-item">
								<h4>Internship full stack developer</h4>
								<h5>Sep 2016 - Feb 2017</h5>
								<p>
									<em>Zeo BV. Utrecht</em>
								</p>
								<ul>
									<li>Analysing company processes related to support activities</li>
									<li>Interviewing employees to gain a better understanding of how support plays a role in the organisation</li>
									<li>Finding a solution to add structure to support work and increase the customer satisfaction.</li>
									<li>Performing backend/frontend work on various projects.</li>
								</ul>
							</div>
							<div class="resume-item">
								<h4>Part-time Web Developer</h4>
								<h5>Sep 2015 - Jan 2016</h5>
								<p>
									<em>Ritense BV, Amsterdam</em>
								</p>
								<ul>
									<li>Extending the Elsevier Reviewers platform with new functionality</li>
									<li>Performing support related tasks on various projects</li>
								</ul>
							</div>
							<div class="resume-item">
								<h4>Backend Developer internship</h4>
								<h5>Feb 2015 - Sep 2015</h5>
								<p>
									<em>Ritense BV, Amsterdam</em>
								</p>
								<ul>
									<li>Studying the Cold Fusion 6 and CFML language.</li>
									<li>Researching the potential of Amazon AWS.</li>
									<li>Setting up a CDN using AWS S3</li>
									<li>Building a prototype demonstrating the use of AWS Pipelines and AWS SNS</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- End Resume Section -->

			<!-- ======= Strengths Section ======= -->
			<section id="strengths" class="services">
				<div class="container" data-aos="fade-up">
					<div class="section-title">
						<h2>Strengths</h2>
						<p>During my educational and professional career I've developed various strengths that help me deliver the quality I aspire.</p>
					</div>

					<div class="row">
						<div class="col-lg-4 col-md-6" data-aos="zoom-in" data-aos-delay="100">
							<div class="icon-box iconbox-blue">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,521.0016835830174C376.1290562159157,517.8887921683347,466.0731472004068,529.7835943286574,510.70327084640275,468.03025145048787C554.3714126377745,407.6079735673963,508.03601936045806,328.9844924480964,491.2728898941984,256.3432110539036C474.5976632858925,184.082847569629,479.9380746630129,96.60480741107993,416.23090153303,58.64404602377083C348.86323505073057,18.502131276798302,261.93793281208167,40.57373210992963,193.5410806939664,78.93577620505333C130.42746243093433,114.334589627462,98.30271207620316,179.96522072025542,76.75703585869454,249.04625023123273C51.97151888228291,328.5150500222984,13.704378332031375,421.85034740162234,66.52175969318436,486.19268352777647C119.04800174914682,550.1803526380478,217.28368757567262,524.383925680826,300,521.0016835830174"
										/>
									</svg>
									<i class="bx bxl-dribbble"></i>
								</div>
								<span class="h4">Problem solver</span>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
							<div class="icon-box iconbox-orange">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,582.0697525312426C382.5290701553225,586.8405444964366,449.9789794690241,525.3245884688669,502.5850820975895,461.55621195738473C556.606425686781,396.0723002908107,615.8543463187945,314.28637112970534,586.6730223649479,234.56875336149918C558.9533121215079,158.8439757836574,454.9685369536778,164.00468322053177,381.49747125262974,130.76875717737553C312.15926192815925,99.40240125094834,248.97055460311594,18.661163978235184,179.8680185752513,50.54337015887873C110.5421016452524,82.52863877960104,119.82277516462835,180.83849132639028,109.12597500060166,256.43424936330496C100.08760227029461,320.3096726198365,92.17705696193138,384.0621239912766,124.79988738764834,439.7174275375508C164.83382741302287,508.01625554203684,220.96474134820875,577.5009287672846,300,582.0697525312426"
										/>
									</svg>
									<i class="bx bx-file"></i>
								</div>
								<span class="h4">Innovator</span>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
							<div class="icon-box iconbox-pink">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,541.5067337569781C382.14930387511276,545.0595476570109,479.8736841581634,548.3450877840088,526.4010558755058,480.5488172755941C571.5218469581645,414.80211281144784,517.5187510058486,332.0715597781072,496.52539010469104,255.14436215662573C477.37192572678356,184.95920475031193,473.57363656557914,105.61284051026155,413.0603344069578,65.22779650032875C343.27470386102294,18.654635553484475,251.2091493199835,5.337323636656869,175.0934190732945,40.62881213300186C97.87086631185822,76.43348514350839,51.98124368387456,156.15599469081315,36.44837278890362,239.84606092416172C21.716077023791087,319.22268207091537,43.775223500013084,401.1760424656574,96.891909868211,461.97329694683043C147.22146801428983,519.5804099606455,223.5754009179313,538.201503339737,300,541.5067337569781"
										/>
									</svg>
									<i class="bx bx-tachometer"></i>
								</div>
								<span class="h4">Performer</span>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="100">
							<div class="icon-box iconbox-yellow">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,503.46388370962813C374.79870501325706,506.71871716319447,464.8034551963731,527.1746412648533,510.4981551193396,467.86667711651364C555.9287308511215,408.9015244558933,512.6030010748507,327.5744911775523,490.211057578863,256.5855673507754C471.097692560561,195.9906835881958,447.69079081568157,138.11976852964426,395.19560036434837,102.3242989838813C329.3053358748298,57.3949838291264,248.02791733380457,8.279543830951368,175.87071277845988,42.242879143198664C103.41431057327972,76.34704239035025,93.79494320519305,170.9812938413882,81.28167332365135,250.07896920659033C70.17666984294237,320.27484674793965,64.84698225790005,396.69656628748305,111.28512138212992,450.4950937839243C156.20124167950087,502.5303643271138,231.32542653798444,500.4755392045468,300,503.46388370962813"
										/>
									</svg>
									<i class="bx bx-donate-heart"></i>
								</div>
								<span class="h4">Leader</span>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="200">
							<div class="icon-box iconbox-red">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,532.3542879108572C369.38199826031484,532.3153073249985,429.10787420159085,491.63046689027357,474.5244479745417,439.17860296908856C522.8885846962883,383.3225815378663,569.1668002868075,314.3205725914397,550.7432151929288,242.7694973846089C532.6665558377875,172.5657663291529,456.2379748765914,142.6223662098291,390.3689995646985,112.34683881706744C326.66090330228417,83.06452184765237,258.84405631176094,53.51806209861945,193.32584062364296,78.48882559362697C121.61183558270385,105.82097193414197,62.805066853699245,167.19869350419734,48.57481801355237,242.6138429142374C34.843463184063346,315.3850353017275,76.69343916112496,383.4422959591041,125.22947124332185,439.3748458443577C170.7312796277747,491.8107796887764,230.57421082200815,532.3932930995766,300,532.3542879108572"
										/>
									</svg>
									<i class="bx bx-brain"></i>
								</div>
								<span class="h4">Inspirator</span>
							</div>
						</div>

						<div class="col-lg-4 col-md-6 mt-4" data-aos="zoom-in" data-aos-delay="300">
							<div class="icon-box iconbox-teal">
								<div class="icon">
									<svg width="100" height="100" viewBox="0 0 600 600" xmlns="http://www.w3.org/2000/svg">
										<path
											stroke="none"
											stroke-width="0"
											fill="#f5f5f5"
											d="M300,566.797414625762C385.7384707136149,576.1784315230908,478.7894351017131,552.8928747891023,531.9192734346935,484.94944893311C584.6109503024035,417.5663521118492,582.489472248146,322.67544863468447,553.9536738515405,242.03673114598146C529.1557734026468,171.96086150256528,465.24506316201064,127.66468636344209,395.9583748389544,100.7403814666027C334.2173773831606,76.7482773500951,269.4350130405921,84.62216499799875,207.1952322260088,107.2889140133804C132.92018162631612,134.33871894543012,41.79353780512637,160.00259165414826,22.644507872594943,236.69541883565114C3.319112789854554,314.0945973066697,72.72355303640163,379.243833228382,124.04198916343866,440.3218312028393C172.9286146004772,498.5055451809895,224.45579914871206,558.5317968840102,300,566.797414625762"
										/>
									</svg>
									<i class="bx bx-user-plus"></i>
								</div>
								<span class="h4">Team player</span>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- End Services Section -->
		</main>
		<!-- End #main -->
	</div>
</template>

<script>
import '@/assets/scss/app.scss'
import NumberIncrement from '@/components/NumberIncrement'
import Typed from 'typed.js'

export default {
	name: 'PageHome',

	components: {
		NumberIncrement
	},

	props: {
		msg: String
	},

	computed: {
		age() {
			const dt = new Date();
			const diff = dt.getTime() - new Date('1994-12-08').getTime();
			return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
		},

		years() {
			const dt = new Date();
			const diff = dt.getTime() - new Date('2015-09-01').getTime();
			return Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
		}
	},

    mounted() {
        this.initTypedElement();
    },

    methods: {
        initTypedElement() {
            const typedEl = document.getElementById('typed');

            if (typedEl) {
                const typedStrings = typedEl.dataset.typedItems;
                new Typed('#typed', {
                    strings: typedStrings.split(','),
                    loop: true,
                    typeSpeed: 100,
                    backSpeed: 50,
                    backDelay: 2000
                });
            }
        },
    }
}
</script>
